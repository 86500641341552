import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  FileField,
  LongTextField,
  NumberField,
  RelationshipField,
  StringField,
} from "../plugins/Cruxtify/field-types";

import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import { ErCrudModel } from "./ErCrudModel";

export class Attachment extends ErCrudModel {
  protected static _typeLabel = "Attachment";
  protected static _typeLabelPlural = "Attachments";
  protected static asProperty = "attachment";
  public static api = {
    path: "attachments",
  };
  protected static routeBase = "attachments";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected userIdField = ["user"];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "label",
        label: "Label",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "filename",
        label: "File Name",
        computed: true,
      },
    },
    {
      type: "FileProperty",
      opts: {
        name: "s3_id",
        label: "File",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "User",
        relatedModel: "User",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "expert",
        label: "Expert",
        relatedModel: "Expert",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "submission",
        label: "Submission",
        relatedModel: "Submission",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    {
      type: StringField,
      opts: { property: "label", isPrimaryLabelField: true, cols: 12 },
    },
    {
      type: StringField,
      opts: { property: "filename", cols: 12 },
    },
    {
      type: LongTextField,
      opts: { property: "description", cols: 12 },
    },
    {
      type: FileField,
      opts: { property: "s3_id", cols: 12 },
    },
    {
      type: RelationshipField,
      opts: { property: "user", md: 6 },
    },
    {
      type: RelationshipField,
      opts: { property: "expert", md: 6 },
    },
    {
      type: RelationshipField,
      opts: { property: "submission", md: 6 },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: ["label", "description"],
        },
        {
          id: "media",
          fields: ["s3_id", "filename"],
        },
        {
          id: "related",
          fields: ["user", "expert", "submission"],
        },
        {
          id: "meta",
          fields: ["created_at", "updated_at", "id"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["label", "expert", "submission", "description"],
      enableSearch: true,
      enableDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: [
          "label",
          "user",
          "description",
          "filename",
          "created_at",
          "expert",
          "submission",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];
}
