import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  NumberField,
  RelationshipField,
  RelationshipFieldMany,
  SelectField,
  StringField,
} from "../plugins/Cruxtify/field-types";

import { ExpertStatus, getAsChoices, PartyType } from "../enums";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import { ErCrudModel } from "./ErCrudModel";

export class Expert extends ErCrudModel {
  protected static _typeLabel = "Expert";
  protected static asProperty = "expert";
  public static api = {
    path: "experts",
  };
  protected static routeBase = "experts";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected userIdField = ["user"];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "name",
        label: "Display Name",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "preferred_party_type",
        label: "Preferred Party Type",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "status",
        label: "Status",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "User",
        relatedModel: "User",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "specialties",
        label: "Specialities",
        relatedModel: "Specialty",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "subspecialties",
        label: "Subspecialities",
        relatedModel: "Subspecialty",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "expert_matches",
        label: "Expert Matches",
        relatedModel: "ExpertMatch",
        foreignProperty: "expert",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "selected_for",
        label: "Engaged Submissions",
        relatedModel: "Submission",
        foreignProperty: "selected_expert",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    {
      type: StringField,
      opts: { property: "name", isPrimaryLabelField: true, cols: 12 },
    },
    {
      type: SelectField,
      opts: {
        property: "status",
        cols: 4,
        options: getAsChoices(ExpertStatus),
        nullLabel: "No Preference",
      },
    },
    {
      type: SelectField,
      opts: {
        property: "preferred_party_type",
        cols: 4,
        options: getAsChoices(PartyType),
      },
    },

    {
      type: RelationshipField,
      opts: {
        property: "user",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "specialties",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "subspecialties",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "expert_matches",
        label: "Matched Submissions",
        collectionLayoutOpts: {
          enableSearch: false,
          noRefresh: true,
          enableDynamicFilters: false,
        },
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "selected_for",
        label: "Engaged Submissions",
        collectionLayoutOpts: {
          enableSearch: false,
          noRefresh: true,
          enableDynamicFilters: false,
        },
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: [
            "name",
            "status",
            "preferred_party_type",
            "specialties",
            "subspecialties",
          ],
        },
        {
          id: "experts",
          fields: ["expert_matches", "selected_for"],
        },
        {
          id: "meta",
          fields: ["user", "created_at", "updated_at", "id"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      columnFields: [
        "name",
        "status",
        "specialties",
        "subspecialties",
        "preferred_party_type",
      ],
      enableSearch: true,
      enableDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: [
          "created_at",
          "name",
          "preferred_party_type",
          "status",
          "specialties",
          "subspecialties",
          "expert_matches",
          "selected_for",
          "updated_at",
          "user",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];
}
