export enum PartyType {
  Plaintiff = "plaintiff",
  Defendant = "defendant",
}

export enum SubmissionType {
  Automatic = "automatic",
  Custom = "custom",
}

export enum SubmissionStatus {
  Draft = "draft",
  PendingPayment = "pending_payment",
  PendingReview = "pending_review",
  Soliciting = "soliciting",
  Active = "active",
  Fulfilled = "fulfilled",
  Expired = "expired",
  Cancelled = "cancelled",
}

export enum ExpertStatus {
  Pending = "pending",
  Active = "active",
  Denied = "paused",
  Closed = "disabled",
}

export enum ExpertAcceptanceStatus {
  Pending = "pending",
  Solicited = "solicited",
  Accepted = "accepted",
  Declined = "declined",
  Rejected = "rejected",
  Expired = "expired",
}

export enum UserRole {
  Admin = "admin",
  Expert = "expert",
  Client = "client",
}

export enum Urgency {
  Urgent = "urgent",
  NotUrgent = "not_urgent",
}

export function getAsChoices<T extends Record<string, string>>(
  enumType: T
): { [key in T[keyof T]]: keyof T } {
  return Object.keys(enumType).reduce((acc, key) => {
    acc[enumType[key as keyof T] as T[keyof T]] = key as keyof T;
    return acc;
  }, {} as { [key in T[keyof T]]: keyof T });
}
