import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  LongTextField,
  NumberField,
  RelationshipField,
  RelationshipFieldMany,
  SelectField,
  StringField,
} from "../plugins/Cruxtify/field-types";

import {
  getAsChoices,
  PartyType,
  SubmissionStatus,
  SubmissionType,
  Urgency,
} from "../enums";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import { ErCrudModel } from "./ErCrudModel";

export class Submission extends ErCrudModel {
  protected static _typeLabel = "Submission";
  protected static asProperty = "submission";
  public static api = {
    path: "submissions",
  };
  protected static routeBase = "submissions";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected userIdField = ["user"];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "title",
        label: "Title",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Description",
        rules: ["required"],
        maxLength: 1000,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "type",
        label: "Submission Type",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "party_type",
        label: "Party Type",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "urgency",
        label: "Urgency",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "status",
        label: "Status",
        rules: ["required"],
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "state",
        label: "State",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "selected_expert",
        label: "Engaged Expert",
        relatedModel: "Expert",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "specialty",
        label: "Specialty",
        relatedModel: "Specialty",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "credit",
        label: "Credit",
        relatedModel: "Credit",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "subspecialty",
        label: "Subspecialty",
        relatedModel: "Subspecialty",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "User",
        relatedModel: "User",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "expert_matches",
        label: "Expert Matches",
        relatedModel: "ExpertMatch",
        foreignProperty: "submission",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    {
      type: StringField,
      opts: { property: "title", isPrimaryLabelField: true, cols: 12 },
    },
    {
      type: LongTextField,
      opts: { property: "description" },
    },
    {
      type: SelectField,
      opts: {
        property: "type",
        cols: 4,
        options: getAsChoices(SubmissionType),
      },
    },
    {
      type: SelectField,
      opts: {
        property: "party_type",
        cols: 4,
        options: getAsChoices(PartyType),
      },
    },
    {
      type: SelectField,
      opts: {
        property: "urgency",
        cols: 4,
        options: {
          [Urgency.NotUrgent]: "Not Urgent",
          [Urgency.Urgent]: "Urgent",
        },
      },
    },
    {
      type: SelectField,
      opts: {
        property: "status",
        cols: 4,
        options: getAsChoices(SubmissionStatus),
      },
    },
    {
      type: SelectField,
      opts: {
        property: "state",
        cols: 12,
        nullLabel: "Select a State/Territory...",
        options: {
          AL: "Alabama",
          AK: "Alaska",
          AZ: "Arizona",
          AR: "Arkansas",
          CA: "California",
          CO: "Colorado",
          CT: "Connecticut",
          DE: "Delaware",
          FL: "Florida",
          GA: "Georgia",
          HI: "Hawaii",
          ID: "Idaho",
          IL: "Illinois",
          IN: "Indiana",
          IA: "Iowa",
          KS: "Kansas",
          KY: "Kentucky",
          LA: "Louisiana",
          ME: "Maine",
          MD: "Maryland",
          MA: "Massachusetts",
          MI: "Michigan",
          MN: "Minnesota",
          MS: "Mississippi",
          MO: "Missouri",
          MT: "Montana",
          NE: "Nebraska",
          NV: "Nevada",
          NH: "New Hampshire",
          NJ: "New Jersey",
          NM: "New Mexico",
          NY: "New York",
          NC: "North Carolina",
          ND: "North Dakota",
          OH: "Ohio",
          OK: "Oklahoma",
          OR: "Oregon",
          PA: "Pennsylvania",
          RI: "Rhode Island",
          SC: "South Carolina",
          SD: "South Dakota",
          TN: "Tennessee",
          TX: "Texas",
          UT: "Utah",
          VT: "Vermont",
          VA: "Virginia",
          WA: "Washington",
          WV: "West Virginia",
          WI: "Wisconsin",
          WY: "Wyoming",
          DC: "District of Columbia",
          AS: "American Samoa",
          GU: "Guam",
          MP: "Northern Mariana Islands",
          PR: "Puerto Rico",
          VI: "Virgin Islands",
          UM: "U.S. Minor Outlying Islands",
        },
      },
    },

    {
      type: RelationshipField,
      opts: { property: "specialty", md: 6 },
    },
    {
      type: RelationshipField,
      opts: { property: "subspecialty", md: 6 },
    },

    {
      type: RelationshipField,
      opts: { property: "selected_expert", md: 6 },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "expert_matches",
        cols: 12,
        collectionLayoutOpts: {
          useDynamicFields: false,
          enableSearch: false,
          noRefresh: true,
          enableDynamicFilters: false,
        },
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipField,
      opts: { property: "user" },
    },
    {
      type: RelationshipField,
      opts: { property: "credit" },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: [
            "title",
            "status",
            "type",
            "state",
            "specialty",
            "subspecialty",
            "description",
            "party_type",
            "urgency",
          ],
        },
        {
          id: "experts",
          fields: ["selected_expert", "expert_matches"],
        },
        {
          id: "meta",
          fields: ["credit", "user", "created_at", "updated_at", "id"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      columnFields: [
        "created_at",
        "status",
        "title",
        "user",
        "specialty",
        "type",
        "party_type",
      ],
      enableSearch: true,
      enableDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: [
          "created_at",
          "description",
          "party_type",
          "selected_expert",
          "specialty",
          "subspecialty",
          "state",
          "status",
          "urgency",
          "title",
          "updated_at",
          "user",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];
}
