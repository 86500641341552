import { ConditionalComparer } from "../conditionals/CompareConditional";

export const equals: ConditionalComparer = (
  propValue: any,
  compareValue: any
) => {
  console.log(propValue, compareValue);
  // only accept null if that's what we're explicitly checking for
  if (propValue === null && compareValue !== null) return false;

  // when propval is array & only has one element, compare that element
  if (Array.isArray(propValue) && propValue.length === 1)
    return propValue[0] == compareValue;

  return propValue == compareValue;
};

export const notEquals: ConditionalComparer = (
  propValue: any,
  compareValue: any
) => {
  return !equals(propValue, compareValue);
};

export const oneOf: ConditionalComparer = (
  propValue: any,
  compareValue: any[]
) => {
  return compareValue.some((compVal) => equals(propValue, compVal));
};

export const notOneOf: ConditionalComparer = (
  propValue: any,
  compareValue: any[]
) => {
  return !oneOf(propValue, compareValue);
};

export const contains: ConditionalComparer = (propValue, compareValue: any) => {
  if (propValue === null && compareValue !== null) return false;
  if (!propValue.includes) {
    return console.error(
      'Property value is not of correct type (does not have an "includes" method) ',
      typeof propValue,
      propValue,
      compareValue
    );
  }
  return propValue.includes(compareValue);
};

export const empty: ConditionalComparer = (
  propValue: any,
  compareValue: any = null
) => propValue !== false && !propValue;

export const notEmpty: ConditionalComparer = (
  propValue: any,
  compareValue: any = null
) => !empty(propValue, null);

export const lengthIs: ConditionalComparer = (
  propValue: any,
  compareValue: any = null
) => propValue.length == compareValue;

export const lengthIsNot: ConditionalComparer = (
  propValue: any,
  compareValue: any = null
) => propValue.length != compareValue;

export const lengthGreaterThan: ConditionalComparer = (
  propValue: any,
  compareValue: any = null
) => propValue.length > compareValue;

export const lengthLessThan: ConditionalComparer = (
  propValue: any,
  compareValue: any = null
) => propValue.length < compareValue;

// TODO: generate these for collections (any* all* across the above comparers)
export const anyEquals: ConditionalComparer = (
  propValue: any,
  compareValue: any = null
) => propValue.some((val) => equals(val, compareValue));
