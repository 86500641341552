export type ConditionalType = typeof Conditional;
export type IConditionalType = ConditionalType | string;

export interface HasConditional {}

export type IConditional = {};

export class Conditional {
  protected owner: HasConditional;

  private static _conditionals: Record<string, ConditionalType> = {};
  public static getConditionalType(
    conditional: string | ConditionalType
  ): ConditionalType {
    if (typeof conditional !== "string") return conditional as ConditionalType;

    if (!this._conditionals[conditional]) {
      console.error(this._conditionals);
      throw new Error("ConditionalType not registered: " + conditional);
    }

    return this._conditionals[conditional] as ConditionalType;
  }
  public static registerConditionalType(
    name: string,
    conditional: ConditionalType
  ) {
    if (this._conditionals[name]) {
      console.error(this._conditionals[name]);
      throw new Error("ConditionalType name already exists: " + name);
    }

    this._conditionals[name] = conditional;
  }
  public static registerConditionalTypes(
    registrationMap: Record<string, ConditionalType> = {}
  ) {
    Object.keys(registrationMap).forEach((name: string) =>
      this.registerConditionalType(name, registrationMap[name])
    );
  }

  constructor(opts: IConditional, owner: HasConditional) {
    this.owner = owner;
  }

  public get result(): boolean {
    return true;
  }

  // TODO: prob refactor this to be more explicit and less guessy, particularly once def UI is built
  // Factory method
  public static newInstance(
    opts: IConditional | any,
    owner: HasConditional
  ): Conditional {
    let type: string = "";

    // property?
    if (typeof opts.property !== "undefined") {
      type = opts.useChanges
        ? "PropertyChangesConditional"
        : "PropertyConditional";
    }

    // field?
    if (typeof opts.field !== "undefined") {
      type = "FieldConditional";
    }

    // attribute?
    if (typeof opts.attribute !== "undefined") {
      type = "AttributeConditional";
    }

    // grouped?
    if (typeof opts.conditionals !== "undefined") {
      type = "GroupedConditional";
    }

    if (type.length === 0)
      throw new Error(
        "Unable to create conditional from opts: " + JSON.stringify(opts)
      );

    return new (this.getConditionalType(type))(opts, owner);
  }
}
