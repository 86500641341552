
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      clipped: false,
      drawer: true,
      fixed: false,
      accountLoading: false,
      accountMenuItems: [
        {
          icon: "mdi-account-circle",
          title: "My Account",
          to: "/my-account",
          // to: "/users/{current_user}"
        },
        {
          icon: "mdi-exit-to-app",
          title: "Logout",
          click: function () {
            this.accountLoading = true;
            this.$auth.logout().then(() => {
              this.accountLoading = false;
              this.$router.push({
                path: "/login",
              });
            });
          }.bind(this),
        },
      ],
      allPrimaryMenuItems: [
        {
          icon: "mdi-post-outline",
          title: "Submissions",
          to: "/submissions",
        },
        {
          icon: "mdi-account-tie",
          title: "Experts",
          to: "/experts",
        },
        {
          icon: "mdi-attachment",
          title: "Attachments",
          to: "/attachments",
        },
        {
          icon: "mdi-message-badge-outline",
          title: "Notifications",
          to: "/notifications",
        },
        {
          icon: "mdi-account",
          title: "Users",
          to: "/users",
        },
        {
          icon: "mdi-currency-usd",
          title: "Credits",
          to: "/credits",
        },
      ],
      allSystemMenuItems: [
        {
          icon: "mdi-shape",
          title: "Specialties",
          to: "/specialties",
        },
        {
          icon: "mdi-shape",
          title: "Subspecialties",
          to: "/subspecialties",
        },
        {
          icon: "mdi-shape",
          title: "Mailer Templates",
          to: "/mailer-templates",
        },
      ],
      miniVariant: false,
    };
  },
  mounted() {
    if (typeof this.$route.query.debug !== "undefined") {
      this.$config.debug = this.$route.query.debug == "1" ? true : false;
    }
  },
  computed: {
    ...mapGetters("app", ["title"]),
    activePrimaryMenuItems() {
      return this.allPrimaryMenuItems.filter(
        (item) => !item.adminOnly || this.$auth.user.is_admin
      );
    },
    activeAccountMenuItems() {
      return this.accountMenuItems.filter(
        (item) => !item.adminOnly || this.$auth.user.is_admin
      );
    },
    activeSecondaryMenuItems() {
      return this.allSecondaryMenuItems.filter((item) =>
        this.showItemForCurUser(item, this.$auth.user)
      );
    },
    userName() {
      if (!this.$auth.loggedIn) return "";
      return this.$auth.user.first_name;
    },
    showSystemMenu() {
      return this.$auth.user.is_admin;
    },
  },
  methods: {
    decorateTo(to) {
      if (to && to.indexOf("{current_user}") !== -1) {
        to = to.replace("{current_user}", this.$auth.user.id);
      }
      return to;
    },
  },
};
