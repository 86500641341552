import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  NumberField,
  RelationshipField,
  SelectField,
} from "../plugins/Cruxtify/field-types";

import { ExpertAcceptanceStatus, getAsChoices } from "../enums";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import { ErCrudModel } from "./ErCrudModel";

export class ExpertMatch extends ErCrudModel {
  protected static _typeLabel = "Expert Match";
  protected static asProperty = "expert_match";
  public static api = {
    path: "expert-matches",
  };
  protected static routeBase = "expert-matches";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected userIdField = [];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "expert",
        label: "Expert",
        relatedModel: "Expert",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "submission",
        label: "Submission",
        relatedModel: "Submission",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "status",
        label: "Status",
        rules: ["required"],
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },

    {
      type: SelectField,
      opts: {
        property: "status",
        cols: 4,
        options: getAsChoices(ExpertAcceptanceStatus),
      },
    },

    {
      type: RelationshipField,
      opts: { property: "submission", md: 6 },
    },
    {
      type: RelationshipField,
      opts: { property: "expert", md: 6 },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: ["expert", "submission", "status"],
        },
        {
          id: "meta",
          fields: ["created_at", "updated_at", "id"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      columnFields: ["created_at", "expert", "submission", "status"],
      enableSearch: true,
      enableDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: [
          "created_at",
          "status",
          "submission",
          "expert",
          "updated_at",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];
}
