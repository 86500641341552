import { getAsChoices, UserRole as UserRoleEnum } from "../enums";
import {
  DateTimeField,
  EmailField,
  NumberField,
  RelationshipFieldMany,
  SelectField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  DateTimeProperty,
  EmailProperty,
  NumberProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { ErCrudModel } from "./ErCrudModel";

export class User extends ErCrudModel {
  protected static _typeLabel = "User";
  protected static asProperty = "user";
  public static api = {
    path: "users",
  };

  protected userIdField = ["id"];
  protected static routeBase = "users";

  public static modalEdit = true;

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.Read,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "first_name",
        label: "First Name",
        sortable: true,
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "phone",
        label: "Phone Number",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "last_name",
        label: "Last Name",
        sortable: true,
      },
    },
    {
      type: EmailProperty,
      opts: {
        name: "email",
        label: "Email",
        sortable: true,
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "password",
        label: "Password (leave blank to ignore)",
        sortable: false,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_admin",
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "user_role",
        label: "User Role",
        rules: ["required"],
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "stripe_id",
        label: "Stripe ID",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "pm_type",
        label: "Payment Method Type",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "pm_last_four",
        label: "Payment Method Last Four",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "trial_ends_at",
        label: "Trial Ends At",
        userPermissions: UserPermission.Hidden,
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "experts",
        label: "Expert Account",
        relatedModel: "Expert",
        foreignProperty: "user",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "credits",
        label: "Credits",
        relatedModel: "Credit",
        foreignProperty: "user",
      },
    },
  ];
  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    { type: NumberField, opts: { property: "legacy_id" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    {
      type: SelectField,
      opts: { property: "user_role", options: getAsChoices(UserRoleEnum) },
    },
    {
      type: StringField,
      opts: { property: "first_name", isPrimaryLabelField: 1 },
    },
    {
      type: StringField,
      opts: { property: "last_name", isPrimaryLabelField: 2 },
    },
    { type: StringField, opts: { property: "stripe_id" } },
    { type: EmailField, opts: { property: "email" } },
    // { type: StringField, opts: { property: "password" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "experts",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutOpts: {
          useDynamicFields: false,
          enableSearch: false,
          noRefresh: true,
          noNew: true,
          enableDynamicFilters: false,
        },
        // conditional: {
        //   property: "user_role",
        //   value: UserRoleEnum.Expert,
        // },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "credits",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutOpts: {
          useDynamicFields: false,
          enableSearch: false,
          noRefresh: true,
          enableDynamicFilters: false,
        },
        // conditional: {
        //   property: "user_role",
        //   value: UserRoleEnum.Client,
        // },
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: ["first_name", "last_name", "email", "user_role"],
        },
        {
          id: "1",
          fields: ["experts", "credits"],
        },
        {
          id: "meta",
          fields: ["created_at", "updated_at", "id", "stripe_id"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: [
        "created_at",
        "first_name",
        "last_name",
        "email",
        "stripe_id",
        "user_role",
      ],
      useModal: true,
      enableSearch: true,
      enableDynamicFilters: true,
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];
}
