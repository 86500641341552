import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  NumberField,
  RelationshipField,
  StringField,
} from "../plugins/Cruxtify/field-types";

import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import { ErCrudModel } from "./ErCrudModel";

export class Notification extends ErCrudModel {
  protected static _typeLabel = "Notification";
  protected static asProperty = "notification";
  public static api = {
    path: "notifications",
  };
  protected static routeBase = "notifications";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected userIdField = ["user"];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "mailgun_id",
        label: "Mailgun ID",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "status",
        label: "Delivery Status",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "User",
        relatedModel: "User",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "mailer_template",
        label: "Mailer Template",
        relatedModel: "MailerTemplate",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "submission",
        label: "Submission",
        relatedModel: "Submission",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    {
      type: StringField,
      opts: { property: "mailgun_id" },
    },
    {
      type: StringField,
      opts: { property: "status" },
    },
    {
      type: RelationshipField,
      opts: {
        property: "user",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "mailer_template",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "submission",
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: ["user", "status", "mailer_template", "submission"],
        },
        {
          id: "meta",
          fields: ["mailgun_id", "created_at", "updated_at", "id"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      columnFields: ["user", "status", "mailer_template", "submission"],
      enableSearch: true,
      enableDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: [
          "created_at",
          "user",
          "mailer_template",
          "submission",
          "status",
          "updated_at",
          "mailgun_id",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];
}
