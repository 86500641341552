import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  NumberField,
  RelationshipField,
  StringField,
} from "../plugins/Cruxtify/field-types";

import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import { ErCrudModel } from "./ErCrudModel";

export class Credit extends ErCrudModel {
  protected static _typeLabel = "Credit";
  protected static _typeLabelPlural = "Credits";
  protected static asProperty = "credit";
  public static api = {
    path: "credits",
  };
  protected static routeBase = "credits";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected userIdField = ["user"];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "note",
        label: "Note",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "User",
        relatedModel: "User",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "submission",
        label: "Submission",
        relatedModel: "Submission",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    { type: StringField, opts: { property: "note" } },
    {
      type: RelationshipField,
      opts: { property: "user", md: 6, isPrimaryLabelField: 1 },
    },
    {
      type: RelationshipField,
      opts: { property: "submission", md: 6, isPrimaryLabelField: 2 },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "related",
          fields: ["note", "user", "submission"],
        },
        {
          id: "meta",
          fields: ["created_at", "updated_at", "id"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["created_at", "user", "submission", "note"],
      enableSearch: false,
      enableDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: [
          "user",
          "note",
          "created_at",
          "updated_at",
          "user",
          "submission",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];
}
